<template>

    <!--<h1 v-if="sub" class="text-center">{{ title }}</h1>-->
    <h2 class="text-center">{{ title }}</h2>
    <p class="lead">{{ desc }}</p>

    <div class="margin-top-50 person-container">
        <div v-for="person in people" :key="person.id" :title="person.title" class="entry entry-person">
            <router-link :to="'/leiterin/' + person.slug" class="link-more entry-limit">
                <img v-if="person.img" :src="getImgUrl(person.img)" class="entry-image">
                <img v-else src="https://placekitten.com/640/360" class="entry-image">

                <h3 v-if="person.title" class="entry-title">{{ person.title }}</h3>
            </router-link>
            <span v-if="sub">
                <p v-if="person.responsible">{{ person.responsible }}</p>
                <p v-if="person.tel">{{ person.tel }}</p>
                <p v-if="person.email"><a :href="'mailto:'+person.email">{{ person.email }}</a></p>
            </span>
            <span v-else>
                <p v-if="person.function">{{ person.function }}</p>
            </span>
        </div>
    </div>

</template>

<script>
import json from '../assets/json/people.json'

// @ is an alias to /src
export default {
    name: 'AlleLeiterinnen',
    props: {
        title: String,
        desc: String,
        slug: String,
        sub: Boolean,
        filter: Array
    },
    data() {
        return{
            people: json
        }
    },
    methods: {
        getImgUrl: function(filename){
            return require('../assets/img/' + filename)
        },
        filterPersonen: function(){
            this.people = this.people.filter(item => {
                return this.filter.includes(item.slug)
            })
            return this.people
        }
    },
    created() {
        if(this.filter){
            this.filterPersonen();
        }
    },
}
</script>
