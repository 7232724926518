<template>

    <h1 v-if="sub" class="text-center">{{ title }}</h1>
    <h2 v-else class="text-center">{{ title }}</h2>
    <p class="lead">{{ desc }}</p>

    <div class="container-fluid margin-top-50">
        <div class="row eq-height">

            <div v-for="article in sortedArray" :key="article.slug" :title="article.title" :class="'col-xs-12 col-sm-4 entry ' + article.link">
                <a v-if="article.link">
                    <img v-if="article.img" :src="getImgUrl(article.img)" class="entry-image">
                    <img v-else src="https://dummyimage.com/600x400/000/fff" class="entry-image">
                    <h3 v-if="article.title">{{ article.title }}</h3>
                    <p class="entry-teaser" v-if="article.teaser">{{ article.teaser }}</p>
                </a>
                <a v-else @click="redirectUser(article.link, article.slug)" v-bind="article" class="link-more entry-limit">
                    <img v-if="article.img" :src="getImgUrl(article.img)" class="entry-image">
                    <img v-else src="https://dummyimage.com/600x400/000/fff" class="entry-image">
                    <h3 v-if="article.title">{{ article.title }}</h3>
                    <p class="entry-teaser" v-if="article.teaser">{{ article.teaser }}</p>
                    <a class="meta entry-link noline link-next">
                        <span v-if="article.link">  {{ article.link }}&nbsp;</span>
                        <span v-else>Weiterlesen&nbsp;</span>
                        <span class="icon icon-arrow">
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </span>
                    </a>
                </a>
            </div>

        </div>
    </div>

</template>

<script>
import json from '../assets/json/articles.json'

export default {
    //articles: json,
    name: 'AlleArtikel',
    props: {
        title: String,
        desc: String,
        sub: Boolean,
        limit: {
            default: null,
            type: Number
        }
    },
    data() {
        return{
            //articles: this.sortArray( this.limit ? json.slice(0,this.limit) : json )
            //articles: this.limit ? json.slice(0,this.limit) : json
            articles: this.sortedArray
        }
    },
    methods: {
        getImgUrl: function(filename){
            return require('../assets/img/' + filename)
        },
        // eslint-disable-next-line
        redirectUser: function(link, slug) {
            if (link === 'Anmeldung') {
                this.$router.push('/anmeldung')
            } else {
                this.$router.push('/artikel/' + slug)
            }
        }
    },
    computed: {
        sortedArray: function() {
            // Compare by id
            function compare(a, b) {
                if (a.id > b.id)
                    return -1;
                if (a.id < b.id)
                    return 1;
                return 0;
            }
            // Sort data
            var jsonSorted = json.slice(0).sort(compare);
            // Limit number of entries if needed
            return this.limit ? jsonSorted.slice(0,this.limit) : jsonSorted
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

    //.entry:not(.Anmeldung){
    .entry.Anmeldung{
        a{
            cursor: default!important;
        }
    }

</style>
