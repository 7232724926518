<template>
    <div class="home">
        <HelloParents/>
        <AlleArtikel :sub="false" limit="3" title="Aktuelles" desc="Hier finden Sie News und wichtige Informationen rund um die Spielgruppe Gigampfi in Hochdorf" />

        <h2 class="margin-top-75 text-center">Standorte</h2>
        <p class="lead">Die Spielgruppe Hochdorf hat zwei Räume im Pfarreizentrum St. Martin. Die Kinder werden nach Quartier eingeteilt.</p>

        <div class="container-fluid margin-top-50 margin-bottom-75">
            <div class="row">
                <div class="col-xs-12 col-sm-6 entry">
                    <router-link to="/standorte/">
                        <h4>Pfarreizentrum St. Martin A, UG, Zimmer 5</h4>
                        <img src="../assets/img/standort_pfarrei-st-martin_0.png" class="entry-image">
                    </router-link>
                </div>
                <div class="col-xs-12 col-sm-6 entry">
                    <router-link to="/standorte/">
                        <h4>Pfarreizentrum St. Martin A, 2. OG, Zimmer 16</h4>
                        <img src="../assets/img/standort_pfarrei-st-martin.png" class="entry-image">
                    </router-link>
                </div>
                
            </div>
        </div>

        <AllePersonen :sub="false" title="Team" desc="Unsere liebevollen und engagierten Spielgruppenleiterinnen und Assistentinnen stellen sich mit jeweiligem Steckbrief kurz persönlich vor."/>
    </div>
</template>

<script>
// @ is an alias to /src
import HelloParents from '@/components/HeaderHelloParents.vue'
import AlleArtikel from '@/components/AlleArtikel.vue'
import AllePersonen from '@/components/AllePersonen.vue'

export default {
    name: 'Home',
    components: {
        HelloParents,
        AlleArtikel,
        AllePersonen
    }
}
</script>
