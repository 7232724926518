import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/styles/default.less'

const app = createApp(App)
app.use(router)
app.use(VueAxios, axios)

app.mixin({
    methods: {
        toggleBodyClass: function(addRemoveClass, className) {
            const el = document.body;
            if (addRemoveClass === true) {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        }
        /*
        mounted() {
          this.toggleBodyClass('addClass', 'mb-0');
        },
        destroyed() {
          this.toggleBodyClass('removeClass', 'mb-0');
        }
        */
    }
})

app.mount('#app')
