<template>

    <footer>
        <div class="wrapper container-fluid">
            <div class="row">
                <div class="col-xs-6">
                    <img alt="Gigampfi Logo" class="logo" src="../assets/logo_sujet_inverted.svg">
                </div>
                <div class="col-xs-6 text-right">
                    <p class="meta">
                        Spielgruppe Gigampfi Hochdorf<br>
                        <a href="mailto:info@spielgruppehochdorf.ch">info@spielgruppehochdorf.ch</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>

</template>

<script>
export default {
    name: 'Footer',
    props: {
        msg: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
