import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/aktuelles',
        name: 'Aktuelles',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Aktuelles.vue')
    },
    {
        path: '/anmeldung',
        name: 'Anmeldung',
        component: () => import( '../views/Anmeldung.vue')
    },
    {
        path: '/informationen',
        name: 'Informationen',
        component: () => import( '../views/Informationen.vue')
    },
    {
        path: '/geschichte',
        name: 'Geschichte',
        component: () => import( '../views/Geschichte.vue')
    },
    {
        path: '/standorte',
        name: 'Standorte',
        component: () => import( '../views/Standorte.vue')
    },
    {
        path: '/paedagogik',
        name: 'Pädagogik',
        component: () => import( '../views/Spielgruppenpaedagogik.vue')
    },

    {
        path: '/artikel/:slug',
        name: 'Artikel',
        component: () => import( '../views/ArtikelDetail.vue'),
        props: true
    },
    {
        path: '/leiterin/:slug',
        name: 'Leiterin',
        component: () => import( '../views/LeiterinDetail.vue'),
        props: true
    },

    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import( '../views/404.vue')
    },
    {
        path: '/:pathMatch(.*)',
        name: 'bad-not-found',
        component: () => import( '../views/404.vue')
    }

]

const router = createRouter({
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {left: 0, top: 0}
        }
    },
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
