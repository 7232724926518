<template>
    <Header/>
    <main>
        <div class="wrapper">
            <router-view/>
        </div>
    </main>
    <Footer/>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'Home',
    components: {
        Header,
        Footer
    }
}
</script>

<style lang="less">

</style>
