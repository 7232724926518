<template>
    <div class="hello-parents padding">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xs-12 col-ms-11 col-ms-offset-1 col-sm-11 col-sm-offset-1">
                    <h1>Herzlich<br>Willkommen<br>liebe Eltern</h1>
                </div>
            </div>
        </div>
        <div class="svg-wrapper">
            <div class="shape-square"></div>
            <img class="shape noline" src="../assets/shape-hello.svg">
        </div>
    </div>
</template>

<script>
export default {
    name: 'HelloParents',
    props: { }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    .hello-parents{
        min-height: 300px;

        h1{
            color: #fff;
            max-width: 300px;
        }
        .svg-wrapper{
            position: absolute;
            top: 0; left: 0; right: 0;
            width: 100vw;
            z-index: -1;

            .shape-square{
                position: relative;
                height: 400px;
                background-color: #87C9AA;
            }
            .shape{
                position: relative;
                margin: 0!important;
                margin-top: -1px!important;
            }
        }

        @media (max-width: 768px) {
            min-height: 200px;
            .shape-square{
                height: 325px!important;
            }
        }
    }
</style>
